import { Component, MouseEvent } from "react";
import { Grid, CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import Popover from "@mui/material/Popover";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Moment from "moment";

import Api from "../../../services/api";

interface OpenRequestsCardProps { }

interface OpenRequestsState {
  anchorEl: any;
  anchorEl2: any;
  open: boolean;
  open2: boolean;
  openRequests: {
    today: number;
    thisMonth: number;
    last30Days: number;
    lastOneYear: number;
    top25ClientsData: [];
    top25CourtsData: [];
  };
  isLoading: boolean;
}

export default class OpenRequestsCard extends Component<
  OpenRequestsCardProps,
  OpenRequestsState
> {
  constructor(props: OpenRequestsCardProps) {
    super(props);
    this.state = {
      anchorEl: null,
      anchorEl2: null,
      open: false,
      open2: false,
      openRequests: {
        today: 0,
        thisMonth: 0,
        last30Days: 0,
        lastOneYear: 0,
        top25ClientsData: [],
        top25CourtsData: [],
      },
      isLoading: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClick2 = this.handleClick2.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.refreshData = this.refreshData.bind(this);
  }

  handleClick(e: MouseEvent<HTMLButtonElement>) {
    this.setState({ anchorEl: e.currentTarget, open: true });
  }

  handleClick2(e: MouseEvent<HTMLButtonElement>) {
    this.setState({ anchorEl2: e.currentTarget, open2: true });
  }

  handleClose() {
    this.setState({ anchorEl: null, anchorEl2: null, open: false, open2: false });;
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.setState({ isLoading: true });
    Api.get("/OpenRequests/Stats").then((res) => {
      this.setState({ openRequests: res.data, isLoading: false });
    });
  }

  refreshData() {
    this.getData();
  }

  render() {
    const { openRequests, isLoading } = this.state;
    Moment.locale("en");
    return (
      <Card>
        <CardHeader
          action={
            <>
              <IconButton color="info" size="small" onClick={this.handleClick}>
                <VisibilityIcon />
              </IconButton>
              <Popover
                id="top-25-clients-jobs"
                open={this.state.open}
                onClose={this.handleClose}
                anchorEl={this.state.anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <TableContainer sx={{ minWidth: 500, maxHeight: 500 }}>
                  <Typography
                    className="popup-headding"
                    variant="h6"
                    color="primary"
                    component="div"
                  >
                    Top 25 Clients
                  </Typography>
                  <Table aria-label="Top 25 Clients" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell component="th">Client</TableCell>
                        <TableCell component="th" sx={{textAlign:"right"}}>Today</TableCell>
                        <TableCell component="th" sx={{textAlign:"right"}}>In this Month</TableCell>
                        <TableCell component="th" sx={{textAlign:"right"}}>Last One Month</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {openRequests.top25ClientsData &&
                        openRequests.top25ClientsData.map((row: any) => (
                          <TableRow
                            key={row.clientId}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                            {row.clientId}
                            </TableCell>
                            <TableCell sx={{textAlign:"right"}}>{row.today.toLocaleString()}</TableCell>
                            <TableCell sx={{textAlign:"right"}}>{row.thisMonth.toLocaleString()}</TableCell>
                            <TableCell sx={{textAlign:"right"}}>{row.lastMonth.toLocaleString()}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Popover>
              <IconButton
                color="error"
                size="small"
                onClick={this.handleClick2}
              >
                <VisibilityIcon />
              </IconButton>
              <Popover
                id="top-25-courts-jobs"
                open={this.state.open2}
                onClose={this.handleClose}
                anchorEl={this.state.anchorEl2}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <TableContainer sx={{ minWidth: 500, maxHeight: 500 }}>
                  <Typography
                    className="popup-headding"
                    variant="h6"
                    color="primary"
                    component="div"
                  >
                    Top 25 Courts
                  </Typography>
                  <Table aria-label="Top 25 Courts" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell component="th">Court</TableCell>
                        <TableCell component="th" sx={{textAlign:"right"}}>Today</TableCell>
                        <TableCell component="th" sx={{textAlign:"right"}}>In this Month</TableCell>
                        <TableCell component="th" sx={{textAlign:"right"}}>Last One Month</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {openRequests.top25CourtsData &&
                        openRequests.top25CourtsData.map((row: any) => (
                          <TableRow
                            key={row.court}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                             {row.court}
                            </TableCell>
                            <TableCell sx={{textAlign:"right"}}>{row.today.toLocaleString()}</TableCell>
                            <TableCell sx={{textAlign:"right"}}>{row.thisMonth.toLocaleString()}</TableCell>
                            <TableCell sx={{textAlign:"right"}}>{row.lastMonth.toLocaleString()}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Popover>
            </>
          }
          title="Open Requests"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography variant="h5" color="primary">
                {openRequests.today.toLocaleString()}
              </Typography>
              <Typography variant="caption">Today</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" color="primary" align="right">
                {openRequests.thisMonth.toLocaleString()}
              </Typography>
              <Typography variant="caption" display="block" align="right">
                This Month
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" color="primary">
                {openRequests.last30Days.toLocaleString()}
              </Typography>
              <Typography variant="caption">Last 30 Days</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" color="primary" align="right">
                {openRequests.lastOneYear.toLocaleString()}
              </Typography>
              <Typography variant="caption" display="block" align="right">
                Last One Year
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Box sx={{ margin: "0 auto" }}>
            {isLoading ? <CircularProgress color="info" size={20} /> :
              <IconButton color="info" size="small" aria-label="reload data" onClick={this.refreshData}>
                <AutorenewIcon />
              </IconButton>
            }
          </Box>
        </CardActions>
      </Card>
    );
  }
}
