import { Component} from 'react';
import { Grid } from '@mui/material';
import CompletedTime from './CompletedTime';
import SAOSTime from './SAOSTime';
import FAOSTime from './FAOSTime';
import InvoiceTime from './InvoiceTime';

class AverageTimeCards extends Component {
  render() {
    return (
      <Grid container spacing={2} >
        <Grid item xs={6} sm={6} md={3}>
          <CompletedTime></CompletedTime>
        </Grid>        
        <Grid item xs={6} sm={6} md={3}>
          <SAOSTime></SAOSTime>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <FAOSTime></FAOSTime>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <InvoiceTime></InvoiceTime>
        </Grid>
      </Grid>
    );
  }
}

export default AverageTimeCards;
