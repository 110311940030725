import { Component } from "react";
import { Grid } from "@mui/material";
import AverageTimeCards from "../badges/AverageTimeCards";
import CompletedJobsCard from "./widgets/CompletedJobs";
import OpenJobsCard from "./widgets/OpenJobs";
import OpenRequestsCard from "./widgets/OpenRequests";

class Dashboard extends Component {
  render() {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AverageTimeCards />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CompletedJobsCard />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <OpenJobsCard />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <OpenRequestsCard />
          </Grid>
        </Grid>
      </>
    );
  }
}

export default Dashboard;
