import Api from '../../services/api';

// A mock function to mimic making an async request for data
export function getCompletionTime() {
    return new Promise<{ data: any }>((resolve) =>
      Api.get('/Badges/CompletionTime')
      .then((response: any) => {
        setTimeout(() => resolve({ data: response.data }), 500)
      })
    );
  }

  export function getSAOSTime() {
    return new Promise<{ data: any }>((resolve) =>
      Api.get('/Badges/SAOSUploadedTime')
      .then((response: any) => {
        setTimeout(() => resolve({ data: response.data }), 500)
      })
    );
  }

  export function getFAOSTime() {
    return new Promise<{ data: any }>((resolve) =>
      Api.get('/Badges/FAOSUploadedTime')
      .then((response: any) => {
        setTimeout(() => resolve({ data: response.data }), 500)
      })
    );
  }

  export function getInvoicedTime() {
    return new Promise<{ data: any }>((resolve) =>
      Api.get('/Badges/InvoicedTime')
      .then((response: any) => {
        setTimeout(() => resolve({ data: response.data }), 500)
      })
    );
  }