import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter } from "react-router-dom";
import "./index.scss";
import App from "./App";

fetch('./config.json')
.then((res) => {
  res.json().then((data) => {
    Object.keys(data).forEach((key) => {
      localStorage.setItem(key, data[key]);
    });
    
    const root = ReactDOM.createRoot(
      document.getElementById("root") as HTMLElement
    );
    
    root.render(
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    );
  });
});