import { Component, MouseEvent } from "react";
import { Grid, CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import Popover from "@mui/material/Popover";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Moment from "moment";

import Api from "../../../services/api";

interface CompletedJobsCardProps { }

interface CompletedJobsState {
  anchorEl: any;
  anchorEl2: any;
  open: boolean;
  open2: boolean;
  completedJobs: {
    today: number;
    thisMonth: number;
    last30Days: number;
    lastOneYear: number;
    last10DaysData: [];
    last10MonthsData: [];
  };
  isLoading: boolean;
}

export default class CompletedJobsCard extends Component<
  CompletedJobsCardProps,
  CompletedJobsState
> {
  constructor(props: CompletedJobsCardProps) {
    super(props);
    this.state = {
      anchorEl: null,
      anchorEl2: null,
      open: false,
      open2: false,
      completedJobs: {
        today: 0,
        thisMonth: 0,
        last30Days: 0,
        lastOneYear: 0,
        last10DaysData: [],
        last10MonthsData: [],
      },
      isLoading: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClick2 = this.handleClick2.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.refreshData = this.refreshData.bind(this);
  }

  handleClick(e: MouseEvent<HTMLButtonElement>) {
    this.setState({ anchorEl: e.currentTarget, open: true });
  }

  handleClick2(e: MouseEvent<HTMLButtonElement>) {
    this.setState({ anchorEl2: e.currentTarget, open2: true });
  }

  handleClose() {
    this.setState({ anchorEl: null, anchorEl2: null, open: false, open2: false });;
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.setState({ isLoading: true });
    Api.get("/CompletedJobs/Stats").then((res) => {
      this.setState({ completedJobs: res.data, isLoading: false });
    });
  }

  refreshData() {
    this.getData();
  }

  render() {
    const { completedJobs, isLoading } = this.state;
    Moment.locale("en");
    return (
      <Card>
        <CardHeader
          action={
            <>
              <IconButton color="info" size="small" onClick={this.handleClick}>
                <VisibilityIcon />
              </IconButton>
              <Popover
                id="last-10-days-jobs"
                open={this.state.open}
                onClose={this.handleClose}
                anchorEl={this.state.anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <TableContainer>
                  <Typography
                    className="popup-headding"
                    variant="h6"
                    color="primary"
                    component="div"
                  >
                    Last 10 days
                  </Typography>
                  <Table sx={{ minWidth: 300 }} aria-label="Last 10 days Jobs">
                    <TableHead>
                      <TableRow>
                        <TableCell component="th">Date</TableCell>
                        <TableCell component="th" sx={{textAlign:"right"}}>Total Jobs</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {completedJobs.last10DaysData &&
                        completedJobs.last10DaysData.map((row: any) => (
                          <TableRow
                            key={row.date}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {Moment(row.date).format("D MMM YYYY")}
                            </TableCell>
                            <TableCell sx={{textAlign:"right"}}>{row.dayTotal.toLocaleString()}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Popover>
              <IconButton
                color="error"
                size="small"
                onClick={this.handleClick2}
              >
                <VisibilityIcon />
              </IconButton>
              <Popover
                id="last-10-months-jobs"
                open={this.state.open2}
                onClose={this.handleClose}
                anchorEl={this.state.anchorEl2}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <TableContainer>
                  <Typography
                    className="popup-headding"
                    variant="h6"
                    color="primary"
                    component="div"
                  >
                    Last 12 Months
                  </Typography>
                  <Table
                    sx={{ minWidth: 300 }}
                    aria-label="Last 12 Months Jobs"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell component="th">Month</TableCell>
                        <TableCell component="th" sx={{textAlign:"right"}}>Total Jobs</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {completedJobs.last10MonthsData &&
                        completedJobs.last10MonthsData.map((row: any) => (
                          <TableRow
                            key={row.month}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {Moment(row.month + "/1/" + row.year).format(
                                "MMM YYYY"
                              )}
                            </TableCell>
                            <TableCell sx={{textAlign:"right"}}>{row.monthTotal.toLocaleString()}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Popover>
            </>
          }
          title="Completed Jobs"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography variant="h5" color="primary">
                {completedJobs.today.toLocaleString()}
              </Typography>
              <Typography variant="caption">Today</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" color="primary" align="right">
                {completedJobs.thisMonth.toLocaleString()}
              </Typography>
              <Typography variant="caption" display="block" align="right">
                This Month
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" color="primary">
                {completedJobs.last30Days.toLocaleString()}
              </Typography>
              <Typography variant="caption">Last 30 Days</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" color="primary" align="right">
                {completedJobs.lastOneYear.toLocaleString()}
              </Typography>
              <Typography variant="caption" display="block" align="right">
                Last One Year
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Box sx={{ margin: "0 auto" }}>
            {isLoading ? <CircularProgress color="info" size={20} /> :
              <IconButton color="info" size="small" aria-label="reload data" onClick={this.refreshData}>
                <AutorenewIcon />
              </IconButton>
            }
          </Box>
        </CardActions>
      </Card>
    );
  }
}
