import { createTheme, PaletteMode, ThemeProvider } from "@mui/material";
import React from "react";
import ColorContext from "./themes/ColorContext";

import { darkTheme } from "./themes/dark";
import { lightTheme } from "./themes/light";
import { SwitchModeButton } from "./components/switch-mode/SwitchModeButton";
import Dashboard from "./components/dashboard/Dashboard";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import store from "./store";
import { setAccessToken, removeAccessToken } from "./store/authReducer";
import Error401 from "./components/errors/Error401";

const App = () => {
  const tokenParam = new URLSearchParams(useLocation().search).get("token");

  if (tokenParam != null) {
    store.dispatch(setAccessToken(tokenParam));
  } else if (store.getState().auth.accessToken === '') {
    store.dispatch(removeAccessToken());
  }

  const [mode, setMode] = React.useState<PaletteMode>("light");
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) =>
          prevMode === "light" ? "dark" : "light"
        );
      },
    }),
    []
  );
  const theme = React.useMemo(
    () => createTheme(mode === "light" ? lightTheme : darkTheme),
    [mode]
  );
  return (
    <React.Suspense>
      <ColorContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <SwitchModeButton />
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/access-denied" element={<Error401 />} />
          </Routes>
        </ThemeProvider>
      </ColorContext.Provider>
    </React.Suspense>
  );
};

export default App;
