import { Component } from 'react';
import Alert from '@mui/material/Alert';

class Error401 extends Component {

    render() {
        return (
            <Alert severity="error">You don't have access to view the Dashboard</Alert>
        );
    }
}

export default Error401;