import { Component, MouseEvent } from "react";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import AccessTime from "@mui/icons-material/AccessTime";
import CircularProgress from '@mui/material/CircularProgress';
import Link from "@mui/material/Link";
import Popover from "@mui/material/Popover";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Moment from "moment";

import { getInvoicedTime } from "./badgesApi";

class InvoiceTime extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      anchorEl: null,
      open: false,
      invoicedTime: {
        average: 0,
        history: [],
      },
      isLoading: false
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClick(e: MouseEvent<HTMLAnchorElement>) {
    this.setState({ anchorEl: e.currentTarget });
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ anchorEl: null });
    this.setState({ open: false });
  }
  componentDidMount() {
    this.getData();
  }

  getData() {
    this.setState({isLoading: true});
    getInvoicedTime().then((res) => {
      this.setState({ invoicedTime: res.data, isLoading: false});
    });
  }
  render() {
    const { invoicedTime, isLoading } = this.state;
    return (
      <Card
        variant="outlined"
        sx={{ boxShadow: 2, bgcolor: "primary.main", color: "#FFF" }}
      >
        <CardContent>
          <Grid container>
            <Grid item xs={1}>
            {isLoading? <CircularProgress color="info" size={20} /> : <AccessTime />}
            </Grid>
            <Grid item xs={11}>
              <Typography
                alignContent="right"
                sx={{ textAlign: "right", fontSize: "1.25rem" }}
              >
                <Link
                  color="inherit"
                  underline="hover"
                  sx={{cursor: 'pointer'}}
                  href={void(0)}
                  onClick={this.handleClick}
                >
                  {invoicedTime?.average}
                </Link>
                <Popover
                  id="last-14-day"
                  open={this.state.open}
                  onClose={this.handleClose}
                  anchorEl={this.state.anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <TableContainer>
                    <Typography
                      className="popup-headding"
                      variant="h6"
                      color="primary"
                      component="div"
                    >
                      Last 14 Days
                    </Typography>
                    <Table
                      sx={{ minWidth: 350 }}
                      aria-label="Last 10 Months Jobs"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell component="th">Day</TableCell>
                          <TableCell component="th">Average</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {invoicedTime?.history &&
                          invoicedTime?.history.map((row: any) => (
                            <TableRow
                              key={row.day}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {Moment(row.day).format("D MMM YYYY")}
                              </TableCell>
                              <TableCell>{row.average}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Popover>
              </Typography>
              <Typography
                alignContent="right"
                sx={{ textAlign: "right", fontSize: "0.75rem" }}
              >
                Average Days - Job Completion to Job Invoiced
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

export default InvoiceTime;
