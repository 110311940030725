import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
    accessToken: string,
    isAuthenticated: boolean
}
const initialState = { accessToken: '', isAuthenticated: false } as AuthState

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAccessToken: (state, action: PayloadAction<string>) => {
            state.accessToken = action.payload;
            state.isAuthenticated = true;
            localStorage.setItem('accessToken', action.payload);
        },
        removeAccessToken: (state) => {
            state.accessToken = '';
            state.isAuthenticated = false;
            localStorage.removeItem('accessToken');
        }
    },
    extraReducers: {}
});

export const { setAccessToken, removeAccessToken } = authSlice.actions;

export default authSlice.reducer;